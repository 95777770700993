<!--
 * @Descripttion:
 * @version:
 * @Author: wenwen
 * @Date: 2024-02-02 14:40:03
 * @LastEditors: wenwen
 * @LastEditTime: 2024-03-04 14:46:12
-->
<template>
  <div class="container-warp">
    <back-title>新增计划</back-title>
    <div class="add-plan-box">
      <el-form ref="addForm" :model="addForm" :rules="rules" label-width="auto">
        <el-row>
          <el-col :span="8" style="padding-right:32px;">
            <el-form-item label="计划名称" prop="planName">
              <el-input
                v-model="addForm.planName"
                placeholder="请输入计划名称"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="padding-right:32px;">
            <el-form-item label="计划编号" prop="studyPlanCode">
              <el-input
                v-model="addForm.studyPlanCode"
                placeholder="请输入计划编号"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="计划时间" prop="planTime">
              <el-date-picker
                v-model="addForm.planTime"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                style="width: 100%"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" style="padding-right:32px;">
            <el-form-item label="计划类型" prop="type">
              <el-select style="width: 100%" v-model="addForm.type">
                <el-option
                  v-for="item in planTypeList"
                  :key="item.id"
                  :label="item.categoryName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" >
            <el-form-item label="可见范围" prop="useType">
              <el-radio-group v-model="addForm.useType" @input="_useRangeRadioChange">
                <el-radio :label="2">可见范围</el-radio>
                <el-radio :label="1">全部</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="" prop="useDeptId" v-if="addForm.useType==2">
              <el-select filterable value-key="id" clearable v-model="addForm.useDeptId" placeholder="请选择" style="width: 275px">
                <el-option
                  v-for="item in visibleDeptList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  @click.native="_selectDept(item)"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" >
            <el-form-item label="计划封面">
              <el-upload
                name="files"
                ref="upload"
                drag
                multiple
                :action="uploadAction"
                :headers="uploadHeader"
                list-type="picture-card"
                :on-success="_handeSuccessImage"
                :on-change="_uploadCoverImage"
                :on-remove="_handleRemove"
                :on-preview="_preview"
                :file-list="imgList"
                :class="imgList.length>=1?'disabled':''"
                :style="[
                  { '--uploadBgColor': uploadBgColor },
                  { '--uploadBorderColor': $store.state.settings.theme },
                ]"
              >
                <div class="upload-box">
                  <i
                    class="iconfont icon-icon_new"
                    :style="{ color: $store.state.settings.theme }"
                  ></i>
                  <el-button type="text" style="margin-right: 0"
                    >上传封面</el-button
                  >
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16" style="padding-right:32px;">
            <el-form-item label="计划描述" prop="description">
              <el-input
                type="textarea"
                autosize
                v-model="addForm.description"
                placeholder="请输入计划描述">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16" style="padding-right:32px;">
            <el-form-item label="计划介绍" prop="introduce">
              <RichTextEditor
                  class="editor"
                  ref="richTextEditor"
                  :content="addForm.introduce"
                  @changeData="_getRichTextHtml"
                />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="footer-box">
        <el-button @click="$router.go(-1)">取消</el-button>
        <el-button type="primary" plain @click="_submit">保存</el-button>
        <el-button type="primary" @click="goEdit">继续编辑</el-button>
      </div>
    </div>
        <!-- 大图预览 -->
        <el-image-viewer
      v-if="dialogVisible"
      :on-close="closeImgViewer"
      :url-list="imagePreviewUrls"
      :z-index="3000"
    />
  </div>
</template>

<script>
import { addStudyPlanApi } from '@/api/study.js'
import backTitle from '@/components/backTitle/index.vue'
import { getCookie } from '@/utils/util'
import RichTextEditor from '@/components/richTextEditor/index.vue'
import { getFindCustomizeTypeApi } from '@/api/settings'
import { getManageDeptListApi } from '@/api/api'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  components: {
    backTitle,
    RichTextEditor,
    ElImageViewer
  },
  data () {
    return {
      addForm: {
        planName: '', // 计划名称
        studyPlanCode: '', // 计划编码
        startTime: '', // 计划开始时间
        endTime: '', // 计划结束时间
        type: '', // 计划类型
        cover: '', // 计划封面
        description: '', // 计划描述
        introduce: '', // 计划介绍
        expiredFlag: 0, // 到结束时间自动结束学习计划 0:否；1:是；
        status: 0, // 状态 0：未开始；1：进行中；2：已结束；
        planTime: [],
        useDeptId: '',
        useDeptName: '',
        useType: ''
      },
      rules: {
        planName: [{ required: true, message: '请输入计划名称', trigger: 'blur' }],
        studyPlanCode: [{ required: true, message: '请输入计划编号', trigger: 'blur' }],
        planTime: [{ required: true, message: '请选择计划时间', trigger: 'change' }],
        useType: [
          { required: true, message: '请选择可见范围', trigger: ['change', 'blur'] }
        ],
        useDeptId: [
          { required: true, message: '请选择部门', trigger: ['change', 'blur'] }
        ]
      },
      planTypeList: [],
      uploadAction: process.env.VUE_APP_BASE_API_IMAGE + '/oss-service/ossObject/upload', // 上传图片路径
      uploadHeader: {
        token: getCookie('token')
      },
      imgList: [],
      uploadBgColor: '',
      visibleDeptList: [], // 可见部门列表
      dialogVisible: false, // 图片预览
      imagePreviewUrls: []
    }
  },
  created () {
    this.uploadBgColor = this._hexToRgb(this.$store.state.settings.theme)
    this._getPlanTypeList()
    this._getManageDeptList()
  },
  methods: {
    _getManageDeptList () {
      getManageDeptListApi().then((res) => {
        if (res.code === 200) {
          this.visibleDeptList = res.data
        }
      })
    },
    _selectDept (item) {
      this.addForm.useDeptId = item.id
      this.addForm.useDeptName = item.name
    },
    // 可见范围选择
    _useRangeRadioChange (value) {
      if (value === 1) {
        this.addForm.useDeptId = null
      } else {
        if (!this.addForm.useDeptId) {
          this.addForm.useDeptId = null
        }
      }
    },
    _getRichTextHtml (html) {
      this.addForm.introduce = html
    },
    // 获取计划类型数据
    _getPlanTypeList () {
      getFindCustomizeTypeApi({
        type: 1
      }).then(res => {
        if (res.code === 200) {
          this.planTypeList = res.data.planType
        }
      })
    },
    goEdit () {
      this._submit('edit')
    },
    // 保存
    _submit (type) {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.addForm.startTime = this.addForm.planTime[0]
          this.addForm.endTime = this.addForm.planTime[1]
          addStudyPlanApi(this.addForm).then(res => {
            if (!type) {
              this.$router.push({
                path: '/studyPlan'
              })
            } else {
              this.$router.push({
                path: `/editPlan?id=${res.data.id}&type=detail`
              })
            }
          })
        }
      })
    },
    // 图片上传成功
    _handeSuccessImage (response, file, fileList) {
      this.imgList = fileList
      const arr = []
      if (fileList.length > 0) {
        fileList.forEach(item => {
          if (item.response) {
            arr.push(item.response.data[0].url)
          } else {
            arr.push(item.url)
          }
        })
      }
      this.addForm.cover = arr.join(',')
    },
    // 上传封面图触发
    _uploadCoverImage (file, fileList) {

    },
    // 删除图片
    _handleRemove (file, fileList) {
      this.imgList = fileList
      let arr = []
      if (fileList.length > 0) {
        fileList.forEach(item => {
          arr.push(item.response.data[0].url)
        })
      } else {
        arr = []
      }
      this.addForm.cover = arr.join(',')
    },
    closeImgViewer () {
      this.dialogVisible = false
    },
    _preview (file) {
      // 图片预览
      this.dialogVisible = true
      this.imagePreviewUrls = [file.response.data[0].url]
    },
    // 悬浮颜色
    _hexToRgb (hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      this.$forceUpdate()
      return result
        ? 'rgba' +
            '(' +
            parseInt(result[1], 16) +
            ',' +
            parseInt(result[2], 16) +
            ',' +
            parseInt(result[3], 16) +
            ',' +
            0.2 +
            ')'
        : null
    }
  }
}
</script>

<style lang="scss" scoped>
.container-warp {
  padding: 16px 58px 0 24px;
  // height: 850px;
  background-color: #ffffff;
  border-radius: 4px;
}

/deep/.el-upload--picture-card{
    background: var(--uploadBgColor) !important;
    border-color: var(--uploadBorderColor) !important;
    width: 90px;
    height: 90px;
    line-height: 20px;
    .upload-box {
      display: flex;
      flex-direction: column;
      margin-top: 22px;
      .iconfont {
        font-size: 17px;
      }
    }
  }
  /deep/.el-upload-dragger{
    width: 90px !important;
    height: 90px !important;
    background-color: transparent;
    border: 1px dashed transparent;
  }
  /deep/.el-upload-list--picture-card .el-upload-list__item {
    width: 90px;
    height: 90px;
  }
  /deep/.el-upload-list--picture-card .el-progress {
    width: 90px !important;
  }
  /deep/.el-progress-circle {
    width: 90px !important;
    height: 90px !important;
  }
  /deep/.el-form-item__label{
    color:  rgba(0, 0, 0, 0.90);
  }
  /deep/.ql-container{
    min-height: 122px;
  }
  .footer-box{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 217px;
    /deep/.el-button{
      margin-left: 16px !important;
      font-size: 14px;
      height: 32px;
      }
  }
  /deep/.disabled .el-upload--picture-card {
    display: none !important;
}

/deep/.disabled .el-button--success.is-plain {
    display: none !important;
}
</style>
